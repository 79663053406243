export default function copyText (text) {
    let regex = /(<([^>]+)>)/ig;
    let plainText = text.replace(regex, "");
    const input = document.createElement('input')
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', plainText)
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    this.$toast('复制成功')
}