import request from "./index";

// 获取用户信息
export const getUserInfoApi = (code) => {
  return request({
    url: `/api/wxAuth/oauthCallback?code=${code}`,
    method: "GET",
  });
};

// 查询当前用户的订单信息
export const getOrderListApi = (memberId) => {
  return request({
    url: `/api/order/orderList`,
    method: 'GET',
    params: memberId
  })
}


// 登出
export const loginOutApi = () => {
  return request({
    url: `/api/front/member/logout`,
    method: 'GET',
  })
}


