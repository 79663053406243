import { eventBus } from "../main";
let socket = null;

const event = new Event("message");

export default {
  connect(token) {
    // 1、 建立连接，使用token建立连接
    // console.log(token);
    // socket = new WebSocket('ws://192.168.1.118:8088/api/websocket/' + token)  // 内网
    // socket = new WebSocket("ws://192.168.1.206:8888/api/websocket/" + token); // 侯海鹏
    socket = new WebSocket("wss://ai.yilianzh.com/api/websocket/" + token); // 公网IP
    socket.onopen = () => {
      // 2、连接成功后，通过eventBus把消息传递出去
      console.log("WebSocket连接已打开");
      eventBus.$emit("websocket-opened");
    };
    socket.onmessage = (event) => {};
    socket.onclose = (event) => {
      if (event.code === 1000) {
        console.log("WebSocket连接被手动关闭");
      } else {
        console.log("WebSocket连接因为超时自动关闭,自动重新连接！");
        eventBus.$emit("websocket-close");
      }
    };
  },
  send(message) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    } else {
      console.error("WebSocket连接未打开");
    }
  },
  close() {
    if (socket) {
      socket.close();
    }
  },

  // 监听服务器传过来的消息
  addEventListener(type, listener) {
    if (socket) {
      socket.addEventListener(type, listener);
    }
  },
};
