<!-- 全局tost插件 -->
<template>
  <div v-if="visible" class="toast">{{ message }}</div>
</template>
  
  <script>
export default {
  data () {
    return {
      visible: false,
      message: "",
    }
  },
};
  </script>
  
  <style lang="scss" scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba($color: #000000, $alpha: 0.4);
  color: #fff;
  padding: 13px 32px;
  border-radius: 4px;
  z-index: 9999;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}
</style>