<template>
  <div>
    <div v-if="isShow" class="pop-up">
      <div class="pop-up-main">
        <div class="pop-up-content">
          <div class="pop-title">服务协议和隐私政策</div>
          <p @click="lookState">
            请你务必认真阅读、充分理解“服务协议”和“隐私政策”各条款，包括但不限于:为了向你提供数据、分享等服务所要获取的权限信息。你可以阅读<span>《服务协议》</span>和<span>《隐私政策》</span>了解详细信息。如您同意，请点击“同意“开始接受我们的服务。
          </p>
        </div>
        <div class="btn-box">
          <div @click="closeClick">关闭页面</div>
          <div @click="agreeClick">同意</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true,
    };
  },
  mounted() {
    let isShowPopUp = localStorage.getItem("isShowPopUp");
    this.isShow = isShowPopUp === null || isShowPopUp == "false" ? true : false;
  },
  methods: {
    lookState() {
      this.$router.push({ path: "/statement" });
    },
    closeClick() {
      localStorage.setItem("isShowPopUp", false);
      window.open("about:blank", "_top").close();
    },
    agreeClick() {
      localStorage.setItem("isShowPopUp", true);
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-up {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  .pop-up-main {
    width: 390px;
    height: 280px;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -195px;
    margin-top: -140px;
    .pop-up-content {
      padding: 24px;
      .pop-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        color: #333333;
        margin-bottom: 14px;
      }
      p {
        text-align: left;
        text-indent: 2em;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        span {
          color: #12a4a5;
        }
      }
    }
    .btn-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 60px;
      text-align: center;
      border-top: 1px solid #e5e5e5;
      div {
        width: 45%;
        display: inline-block;
        line-height: 60px;
        cursor: pointer;
        &:nth-child(1) {
          border-right: 1px solid #e5e5e5;
        }
      }
    }
  }
}
</style>
