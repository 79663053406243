<template>
  <div>
    <!-- 导航栏 -->
    <navbar leftText="返回" title="Elion Chat" :showShare="false"></navbar>

    <!-- 正文 -->
    <div class="content"></div>
  </div>
</template>

<script>
import navbar from "@/components/content/navbar.vue";

export default {
  components: { navbar },
  mounted() {
    // 本地测试用
    sessionStorage.setItem(
      "userInfo",
      '{"isAdmin":"2","isOperation":"1","memberId":"1680452755044438017","openid":"oS11W6H6ah_HxPz-rRno0vCyyAuY","nickname":"momo","sex":"0","province":"","city":"","country":"","headimgurl":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM5fL8co4BVvicFicNCKiaPg9gdxQolm1tgkicvxQVIDD3uvponnQLMY5SxA3BibyoVnxQojh5iajhjsic1AA/132","unionid":"o9mX35i8kM5fuDZ_f6hs592nGr2c","createTime":"2023-07-16 13:42:13","memberType":{"value":"2","label":"免费会员"},"invitationCode":"bae968"}'
    );
    sessionStorage.setItem("memberId", "1680452755044438017");
    sessionStorage.setItem(
      "token",
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJ3ZWIiLCJpc3MiOiJlbGlvbi1jaGF0IiwiZXhwIjoxNjkzNTU2NTYzLCJpYXQiOjE2OTA5NjQ1NjMsImp0aSI6IjE2ODA0NTI3NTUwNDQ0MzgwMTciLCJ1c2VybmFtZSI6Im1vbW8ifQ.o2Wab60L3m30mnHEhH20x3HkH-cbYdjB_MQG7OxYCPg"
    );
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__title {
  font-family: DINCondensed-Bold, DINCondensed;
  font-size: 36px;
  font-weight: bold;
  color: #212121;
}
</style>
