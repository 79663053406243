import axios from "axios";

const baseURL = "https://ai.yilianzh.com"; // 公网IP
// const baseURL = "http://192.168.1.118:8088" // 内网
// const baseURL = "http://192.168.1.206:8888"; // 侯海鹏

// 封装公共请求方法
const instance = axios.create({
  baseURL,
  headers: {
    "content-type": "application/json",
    "Cache-Control": "no-cache", //解决[from disk cache] 问题
  },
  timeout: 50000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // console.log("路过了请求拦截器");
    // console.log(config.headers);
    if (config.headers.token == undefined || config.headers.token == null) {
      config.headers.token = sessionStorage.token;
    }
    if (config.type === "keyValue") {
      config.headers["Content-Type"] = "multipart/form-data"; // 文档上传使用 multipart/form-data
      config.timeout = 120000;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
