import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  persist: true, // 添加 persist 属性
  state: () => ({
    userInfo: {},
    leavePoints: 0,
    token: "",
    activePath: "/userInfo",
    parentMessageId: "",
    chatGptModule: "gpt-3.5-turbo-16k",
    selectDisabled: false, // GPT模型选择是否禁用
  }),
  actions: {
    // steActivePath(path) {
    //     this.activePath = path
    // },
  },
});
