import axios from "axios";

// 调用聊天chatGPT接口
export const chatHandlerFun = function (data) {
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: "https://ai.yilianzh.com/api/chatBoot",
      data,
      headers: {
        "Content-Type": "application/json",
        token: sessionStorage.token,
      },
    })
      .then(({ data }) => {
        resolve(data);
        // return data
      })
      .catch((err) => {
        this.$message.warning(err.message);
        reject(err.message);
      });
  });
};
