import ToastComponent from "./Toast.vue";

const Toast = {};

Toast.install = function (Vue, options) {
  const ToastConstructor = Vue.extend(ToastComponent);
  const instance = new ToastConstructor();
  instance.$mount(document.createElement("div"));
  document.body.appendChild(instance.$el);

  Vue.prototype.$toast = (message, duration = 1000) => {
    instance.message = message;
    instance.visible = true;
    setTimeout(() => {
      instance.visible = false;
    }, duration);
  };
};

export default Toast;