<template>
  <div>
    <div v-if="isShow" class="pop-up">
      <div class="pop-up-main">
        <!-- 表单名称 -->
        <div class="pop-title">
          <div>
            <span class="title">创建应用</span>
            <span v-if="!isOK" class="tips">
              <img src="../../assets/images/hint.png" alt="" />
              <span>注意，需要测试后才能创建应用</span>
            </span>
          </div>
          <img
            @click="() => (isShow = false)"
            src="../../assets/images/close.png"
            alt=""
          />
        </div>
        <!-- 表单 -->
        <div v-if="!isOK" class="form-box">
          <div class="form-item">
            <span><i class="valid">*</i>应用名称：</span>
            <input
              type="text"
              v-model="form.appTitle"
              placeholder="请输入应用名称"
            />
            <div class="valid" v-if="!validation.appTitle.isValid">
              {{ validation.appTitle.message }}
            </div>
          </div>
          <div class="form-item">
            <span><i class="valid">*</i>应用描述：</span>
            <input
              type="text"
              v-model="form.appDesc"
              placeholder="请输入应用描述，形容你创建的应用功能"
            />
            <div class="valid" v-if="!validation.appDesc.isValid">
              {{ validation.appDesc.message }}
            </div>
          </div>
          <div class="form-item">
            <span><i class="valid">*</i>应用指令：</span>
            <textarea
              v-model="form.presetCommand"
              placeholder="请输入应用指令，例如“你是一个翻译官，无论接下来输入什么，你都需要翻译成中文。内容是：xxxxxx”"
            ></textarea>
            <div class="valid" v-if="!validation.presetCommand.isValid">
              {{ validation.presetCommand.message }}
            </div>
            <p>指令需清晰易懂，明确且有逻辑。让ElionChat化身你的小帮手吧。</p>
          </div>
          <div class="form-item">
            <span><i class="valid">*</i>示例输入：</span>
            <input
              type="text"
              v-model="form.presetTemplate"
              placeholder="I'm your smart helper."
            />
            <div class="valid" v-if="!validation.presetTemplate.isValid">
              {{ validation.presetTemplate.message }}
            </div>
          </div>
          <div class="test-result" v-html="testCentent"></div>
          <!-- 提交按钮 -->
          <div class="pop-btn-box">
            <div @click="testUse">
              <img
                v-if="loading"
                class="mr-3 rotate test-loading"
                src="../../assets/images/loading.png"
                alt=""
              />
              <span v-else>测试</span>
            </div>
            <div @click="submitUse" :class="[isTest ? 'tested' : '']">创建</div>
          </div>
        </div>
        <!-- 创建成功后展示成功页面 -->
        <div v-else class="result-box">
          <div>
            <img src="../../assets/images/succeed.png" alt="" />
            <div class="result-name">创建成功</div>
            <p>
              你的应用创建成功，将在1个工作日内进行后台审核。<span
                >{{ countDown }}s</span
              >后返回首页
            </p>
            <div @click="backAdd" class="result-btn">立即返回</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import mdAttrs from "markdown-it-attrs";
const md = new MarkdownIt().use(mdAttrs);
export default {
  data() {
    return {
      isShow: false,
      form: {
        appTitle: "",
        appDesc: "",
        presetCommand: "",
        presetTemplate: "",
      },
      // 校验信息
      validation: {
        appTitle: {
          isValid: true,
          message: "请输入应用名称",
        },
        appDesc: {
          isValid: true,
          message: "请输入应用描述",
        },
        presetCommand: {
          isValid: true,
          message: "请输入应用指令",
        },
        presetTemplate: {
          isValid: true,
          message: "请输入示例",
        },
        // 其他字段的校验状态和提示信息
      },
      isTest: false,
      testCentent: "这里是测试结果",
      isOK: false,
      countDown: 5,
      timer: null,
      chatId: "",
      // 1为创建，
      type: 1,
      loading: false,
      memberId: "",
      // memberId: "1678572826723975170",
      parentMessageId: "",
    };
  },

  computed: {
    isFormValid() {
      // 遍历所有校验状态，如果有一个不合法，整个表单就不合法
      for (const key in this.validation) {
        if (!this.validation[key].isValid) {
          return false;
        }
      }
      return true;
    },
  },

  watch: {
    // form: {
    //   handler (newVal) {
    //     // 遍历所有表单字段，并进行校验
    //     for (const key in newVal) {
    //       if (newVal.hasOwnProperty(key)) {
    //         const value = newVal[key]
    //         this.validateField(key, value)
    //       }
    //     }
    //   },
    //   deep: true
    // }
  },

  created() {
    // 判断用户是否登录
    let memberId = sessionStorage.getItem("memberId");
    if (memberId !== null && memberId !== "") {
      this.memberId = memberId;
    }
  },

  async mounted() {
    let token = sessionStorage.token;
    if (token !== null && token !== undefined && token !== " ") {
      await this.connectSocket();
    }
  },
  methods: {
    // 连接WebSocket
    async connectSocket() {
      await this.$socket.connect(sessionStorage.getItem("token"));
      // 接收WebSocket消息
      this.$socket.addEventListener("message", (event) => {
        let json_data = JSON.parse(event.data);
        if (json_data.content == "[DONE]") {
          this.testCentent = md.render(this.testCentent);
          this.isTest = true;
          this.loading = false;
          this.type = 1;
          return;
        }

        if (json_data.content == null || json_data.content == "null") {
          return;
        }
        this.setText(json_data.content);
        this.type = 2;
      });
    },

    // 更新回答
    setText(text) {
      if (this.type === 1) {
        this.testCentent = text;
      } else {
        this.testCentent += text;
      }
      this.toBottom();
    },

    submit() {
      let string = {
        // msg: this.form.presetTemplate,
        // reAnswer: 0, //是否重新回答，0.否 ,1.是
        // testFlag: 1, //是否为测试,1.App测试(不会存储上下文), 2.正式App(根据上下文回答), 3.普通聊天
        // // 这里的AppId请使用字符串形式传递，不然会造成精度丢失的问题
        // chatId: this.chatId, //chatId用于区分是否是一次新的聊天，类似官网的new chat,需要前端随机生成chatId来区分不同的聊天窗口(测试App不需要chatId)
        // presetCommand: this.form.presetCommand,

        modelName: "GPT-3",
        parentMessageId: this.parentMessageId,
        msg: this.form.presetTemplate,
        presetCommand: this.form.presetCommand,
        reAnswer: 0, //是否重新回答，0.否 ,1.是
        // 这里的AppId请使用字符串形式传递，不然会造成精度丢失的问题
        // appId: "1650404471836020737", //app应用id(新增应用时若点击测试按钮，可不传appId)(普通聊天的情况下也不传递appId)
        sendType: 1, //消息类型, 0.心跳检测, 1.App测试(不会存储上下文), 2.正式App(根据上下文回答), 3.普通聊天, 4.文件分析, 5.同声传译
      };
      console.log(string);
      this.$socket.send(JSON.stringify(string)); // 发送消息
    },
    validateField(name, value) {
      if (value.trim() === "") {
        this.validation[name].isValid = false;
      } else {
        this.validation[name].isValid = true;
      }
    },
    validateForm() {
      // 遍历所有表单字段，并进行校验
      for (const key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          const value = this.form[key];
          this.validateField(key, value);
        }
      }
    },
    backAdd() {
      // 清除定时器
      clearInterval(this.timer);
      this.countDown = 5;
      this.isTest = false;
      this.isOK = false;
      this.isShow = false;
    },
    // 测试
    testUse() {
      // 触发校验
      this.validateForm();
      if (this.isFormValid) {
        // 表单校验通过，执行表单提交操作
        this.loading = true;
        this.submit();
      }
    },
    // 提交应用
    submitUse() {
      // 没有测试时 直接返回
      if (!this.isTest) return;

      // 发送请求提交表单
      console.log(this.form, 1111);
      let params = {
        ...this.form,
        auditStatus: 2,
      };
      this.$api.post("/api/chatApps", params).then((response) => {
        this.isOKHandle();
        this.$emit("isOK");
      });
    },
    // 提交成功修改状态
    isOKHandle() {
      this.isOK = true;
      this.timer = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(this.timer);
          this.backAdd(); // 如果倒计时结束就返回
        }
      }, 1000);
    },
    toBottom() {
      document
        .getElementsByClassName("test-result")[0]
        .scrollTo(
          1000,
          document.getElementsByClassName("test-result")[0].scrollHeight
        );
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.pop-up {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .pop-up-main {
    width: 640px;
    // height: 700px;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    top: 5%;
    left: 50%;
    margin-left: -320px;
    padding: 16px 24px 30px 24px;

    .pop-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #e5e5e5;

      .title {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 18px;
      }

      div {
        display: flex;

        .tips {
          font-size: 14px;
          color: #666666;
          line-height: 14px;
          display: flex;
          align-items: center;
        }

        img {
          width: 14px;
          height: 14px;
          margin-left: 8px;
          margin-right: 3px;
        }
      }

      > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .form-item {
      text-align: left;

      span {
        display: inline-block;
        font-size: 14px;
        color: #666666;
        line-height: 14px;
        margin-bottom: 8px;
        margin-top: 24px;
      }

      input,
      textarea {
        padding: 10px 16px;
        width: 100%;
        background: none;
        border: none;
        outline: none;
        color: #666;
        font-size: 14px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        vertical-align: middle;

        &::placeholder {
          font-size: 14px;
          color: #999;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
        }
      }

      textarea {
        height: 60px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      p {
        font-size: 12px;
        color: #666666;
        line-height: 12px;
        margin: 0;
        margin-top: 8px;
        color: #666;
      }
    }

    .test-result {
      margin-top: 32px;
      margin-bottom: 48px;
      width: 100%;
      height: 120px;
      overflow: auto;
      background: #f9fafb;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      padding: 13px 16px;
      text-align: left;
      color: #999;
      font-size: 14px;

      &::-webkit-scrollbar {
        width: 2px;
        border-radius: 2px;
      }
    }

    .pop-btn-box {
      display: flex;
      justify-content: center;

      > div {
        text-align: center;
        line-height: 34px;
        color: #fff;
        width: 80px;
        height: 34px;
        background: #ffb801;
        border-radius: 17px;
        cursor: pointer;

        &:nth-child(2) {
          margin-left: 16px;
          background: #ccc;
          cursor: no-drop;
        }

        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }

      .tested {
        background: #12a4a5 !important;
        cursor: pointer !important;
      }
    }

    .result-box {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;

      img {
        width: 60px;
        height: 60px;
        margin-bottom: 16px;
      }

      .result-name {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin-bottom: 10px;
      }

      p {
        width: 328px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-bottom: 42px;

        span {
          color: #12a4a5;
        }
      }

      .result-btn {
        display: inline-block;
        width: 90px;
        height: 34px;
        background: #12a4a5;
        border-radius: 17px;
        text-align: center;
        line-height: 34px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.valid {
  color: red;
  font-size: 12px;
  margin: 2px;
  vertical-align: middle;
  display: inline-block;
}
</style>
