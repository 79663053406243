<template>
  <van-nav-bar
    :title="title"
    :left-text="leftText"
    left-arrow
    @click-left="onClickLeft"
    @click-right="onClickRight"
  >
    <template v-if="showShare" #right>
      <img
        src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/share.svg"
      />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Elion Chat",
    },
    showShare: {
      type: Boolean,
      default: true,
    },
    leftText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },

    onClickRight() {},
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 25px;
}
</style>
