<template>
  <div>
    <van-tabbar v-model="active" @change="onChange">
      <van-tabbar-item name="/">
        <span>聊天</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat-active.svg'
                : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat.svg'
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/application">
        <span>应用</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/application-active.svg'
                : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/application.svg'
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/function">
        <span>功能</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/function-active.svg'
                : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/function.svg'
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/mine">
        <span>我的</span>
        <template #icon="props">
          <img
            :src="
              props.active
                ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/mine-active.svg'
                : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/mine.svg'
            "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Tabbar",
  data() {
    return {
      active: "/", // 默认为聊天页面
    };
  },

  methods: {
    // 底部菜单栏切换按钮
    onChange(index) {
      console.log(index);
      this.$router.push({
        path: `${index}`,
      });
    },
  },
};
</script>

<style scoped></style>
