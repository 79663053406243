var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-tabbar',{on:{"change":_vm.onChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"/"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat-active.svg'
              : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/chat.svg'}})]}}])},[_c('span',[_vm._v("聊天")])]),_c('van-tabbar-item',{attrs:{"name":"/application"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/application-active.svg'
              : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/application.svg'}})]}}])},[_c('span',[_vm._v("应用")])]),_c('van-tabbar-item',{attrs:{"name":"/function"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/function-active.svg'
              : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/function.svg'}})]}}])},[_c('span',[_vm._v("功能")])]),_c('van-tabbar-item',{attrs:{"name":"/mine"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/mine-active.svg'
              : 'https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/mobileChat/mine.svg'}})]}}])},[_c('span',[_vm._v("我的")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }