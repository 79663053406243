import request from "./index";

// 获取会员档次列表
export const getMemberList = () => {
  return request({
    url: "/api/plan/queryByPage",
    method: "GET",
  });
};

// 获取微信支付生成二维码Qr信息
export const getQrInfo = (data) => {
  return request({
    url: "/api/wxPay/native/prepay",
    method: "GET",
    params: data
  });
};

// 获取订单信息
export const getOrderInfoApi = (orderNo) => {
  return request({
    url: `/api/wxPay/query/${orderNo}`,
    method: "GET",
  });
};

// 获取会员信息
export const getMemberInfoApi = (memberId) => {
  return request({
    url: `/api/front/member/${memberId}`,
    method: "GET",
  });
};

// 获取剩余词数列表
export const getLeaveWordsApi = (memberId) => {
  return request({
    url: `/api/member/equityList/${memberId}`,
    method: "GET",
  });
};

// 获取会员期限列表
export const getMemberDeadlineApi = (memberId) => {
  return request({
    url: `/api/member/finalExpireEquityList/${memberId}`,
    method: "GET",
  });
};